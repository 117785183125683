
//////画像切り替え
$(function () {
  var width = $(window).width();
  if( width < 640 ){
    $("img").each(function(){
      $(this).attr("src", $(this).attr("src").replace("_pc","_sp"));
    })
  }
});
//////ページトップ
$(function () {
  var topBtn = $('#page-top');
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 500) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
    return false;
  });
});
//////スムーススクロール
$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 1500 ;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});
//////スムーススクロールIE
if(navigator.userAgent.match(/(msie|MSIE) 10/i) || navigator.userAgent.match(/(T|t)rident\/7\./) || navigator.userAgent.match(/Edge\/\d+\.\d+/)) {
   $('body').on("mousewheel", function () {
     if(event.preventDefault){
        event.preventDefault();
     }else{
        event.returnValue=false;
     }
    var wd = event.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  });
}
//////ナビゲーション
$(function(){
  $('.menu_trigger').on('click', function () {
    $(this).toggleClass('active');
    $('.header_nav_contents').slideToggle();
    $('.header_contents').toggleClass('nav_active');
  });
});
$('.header_nav_contents a').on('click', function(){
  if (window.innerWidth <= 1050) {
    $('.menu_trigger').click();
  }
});	
$(window).on('resize', function() {
	if( 'none' == $('.header_nav_contents').css('display') ){
		$('.header_nav_contents').attr('style','');
	}
});
//////nav_area
$(function () {
  var width = $(window).width();
  if (width > 1050) {
    $(".h_nav_li").hover(function () {
      $(".nav_area ul").toggleClass("active_s");
      $(this).children(".nav_area").stop().slideToggle();
    });
  } else {
    $(".h_nav_li").click(function () {
      $(this).children(".nav_area").slideToggle();
      $('.h_nav_li').not($(this)).children('.nav_area').slideUp();
    });
  }
});
//////リンク
jQuery(function($) {
  $('.link').css('cursor','pointer');
  $('.link').on('click',function () {
    window.location.href = $(this).find('a').attr('href');
  });
});
//////telリンク
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);

if (!isMobile) {
    $('a[href^="tel:"]').on('click', function(e) {
        e.preventDefault();
    });
}
//////モーダル
	$(function(){
    var winScrollTop;
    $('.js-modal-open').each(function(){
        $(this).on('click',function(){
            winScrollTop = $(window).scrollTop();
            var target = $(this).data('target');
            var modal = document.getElementById(target);
            $(modal).fadeIn();
            return false;
        });
    });
    $('.js-modal-close').on('click',function(){
        $('.js-modal').fadeOut();
        $('body,html').stop().animate({scrollTop:winScrollTop}, 100);
        return false;
    }); 
});

//////モーダルMovie
$(function(){
  var winScrollTop;
  $('.js-modalMovie-open').each(function(){
      $(this).on('click',function(){
          winScrollTop = $(window).scrollTop();
          var target = $(this).data('target');
          var modal = document.getElementById(target);
          $(modal).fadeIn();
          $("#videoPlayer").attr("src", $(this).data("videoId"));
          return false;
      });
  });
  $('.js-modalMovie-close').on('click',function(){
      $('.js-modal').fadeOut();
      $('body,html').stop().animate({scrollTop:winScrollTop}, 100);
      // プレイヤーのsrcをクリア
			$("#videoPlayer").attr("src", "");
      return false;
  }); 
});

//////TOPスライダー
$(function() {
    $('#slick').slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed:4000,
        cssEase: 'ease-out',
        dots:true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '3px',
        arrows:  true,
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
        
        responsive: [{
            breakpoint: 640,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 3,
            }
          }
        ]
    });
});